import React from "react";
import PageBanner from "../../components/PageBanner";
import { Link } from "react-router-dom";
import useScrollUp from "../../utilities/useScrollUp";


function Dinner  () {

  useScrollUp()
  return (
  <React.Fragment>
    <PageBanner heading="Jumpstart Dinner" tagline="A Social Event" backgroundImage="../../images/show/dinnerBanner.png"/>
    <div className="container">
      <h1>Dinner, dancing and mingling</h1>
          <div className="row">
            <div className="col-sm-4 align-items-stretch d-flex">
              <img className="card" src="../images/show/dinnerSquare1.png" alt="dinner event barn"/>
            </div>
            <div className="col-sm-4 align-items-stretch d-flex">
              <img className="card" src="../images/show/dinnerSquare2.png" alt="dinner event tablesetting"/>
            </div>
            <div className="col-sm-4 align-items-stretch d-flex">
              <img className="card" src="../images/show/dinnerSquare3.jpg" alt="dinner event bar"/>   
            </div>
          </div> 
          <hr/>
          <h2>What you can expect</h2>
          <hr/>
          <p>
            Come enjoy an evening of dinner, and mingling at Glenn Ranch with other patrons, old cars and a fabulous country setting in the outskirts of Petaluma. The money raised at the dinner helps support our scholarship program.
            <br/>
            Event Check in 5:30 pm, Dinner - 6:30 pm Seating is limited and only available in advance.
          </p>

          <h2>Whats on the Menu</h2>
          <hr/>
          {/*<p>We will keep you  posted</p>*/}
         <div className="row justify-content-center">
            <div className="col-sm-3">
             <Link to={{ pathname: "https://lombardiscatering.com/"}} target="_blank"><img className="card-img-top" src="../images/show/lombardisLogo.png" alt="Lombardi's Catering and Events"/></Link>
              
            </div>
          </div> 
          
          <p>
            This year we are pleased to be partnering with one of Petaluma's favorites, Lombardi's Catering and Events for the 2024 Jumpstart dinner. 
          </p>
          <p>Hors D'oeuvres</p>
            <ul>
              <li>Steakhouse Bruschetta: Freshly baked crostini's topped with thinly sliced tri-tip, blue cheese drizzle, bruchetta and avocado</li>
              <li>Fig and Brie Canapes: brie topped with fig compote and served on cinnamon crostini</li>
              <li>Mexican Prawn Bite: sauteed spicy prawn served on a bite size crispy tortilla with guacamole and chipolte drizzle</li>
            </ul>
            <p>Main Course</p>
            <ul>
              <li>Tri-tip: Served with creamy horseradish sauce and Lombardi’s Barbecue Sauce</li>
              <li>Champagne Chicken: A delicious chicken in a chapagne cream sauce with mushrooms</li>
              <li>Roasted Garlic Mashed Potatoes: Yukon gold mashed potatoes with butter, cream and roasted garlic seasoned to perfection. </li>
              <li>Grilled Seasonal Vegetables: grilled asparagus, mushrooms and peppers seasoned and cooked to perfection with slight smoky flavor.</li>
              <li>Blend of Field Greens: field greens tossed with candied walnuts, seasonal berries and a raspberry vinaigarette, crumbled goat cheese and parm cheese on side.</li>
              <li>Focaccia Bread: assorted flavors of focaccia served with butter</li>
            </ul>
            
          <h2>Want to bring your car?</h2>
          <hr/>
          <p>
            We would love to see you bring your ride to the Jumpstart dinner, however, note that Glenn Ranch is located off a gravel country road and parking for this is event is either on an uneven field or dirt.  Dinner ticket holders are welcome to bring their classic vehicles to the event at no additional charge. 
          </p>
          <hr/>
          <h2>2023 Event Snap Shots</h2>
          <p>Thinking about attending in 2024? Here are a few shots from our last Jumpstart Dinner Event.</p>
          <div className="row">
            <div className="col-sm-3 align-items-stretch d-flex">
              <img className="card" src="../images/show/23Block1.png" alt="dinner event passing the wheel"/>
            </div>
            <div className="col-sm-3 align-items-stretch d-flex">
              <img className="card" src="../images/show/23Block2.png" alt="dinner event raffel"/>
            </div>
            <div className="col-sm-3 align-items-stretch d-flex">
              <img className="card" src="../images/show/23Block3.png" alt="dinner event cop and coup"/>   
            </div>
            <div className="col-sm-3 align-items-stretch d-flex">
              <img className="card" src="../images/show/23Block4.png" alt="dinner event grounds"/>   
            </div>
          </div> 
          <br/>
          <div className="row">
            <div className="col-sm-3 align-items-stretch d-flex">
              <img className="card" src="../images/show/23Block5.png" alt="dinner event buffet"/>
            </div>
            <div className="col-sm-3 align-items-stretch d-flex">
              <img className="card" src="../images/show/23Block6.png" alt="dinner event food"/>
            </div>
            <div className="col-sm-3 align-items-stretch d-flex">
              <img className="card" src="../images/show/23Block7.png" alt="dinner event attendees"/>   
            </div>
            <div className="col-sm-3 align-items-stretch d-flex">
              <img className="card" src="../images/show/23Block8.png" alt="dinner event barn at night"/>   
            </div>
          </div> 
      </div>
    </React.Fragment>
  );
}


export default Dinner ;