import React from "react";
import PageBanner from "../../components/PageBanner";
import { Link } from "react-router-dom";
import useScrollUp from "../../utilities/useScrollUp";


function Info () {

  useScrollUp()
  return (
  <React.Fragment>
    <PageBanner heading="Car Show Information" tagline="What can you expect" backgroundImage="../../images/show/infoBanner.png"/>
    <div className="container">
      <h1>More than just a car show</h1>
          <div className="row">
            <div className="col-sm-4 align-items-stretch d-flex">
              <img className="card" src="../images/show/cruiseSquare2.png" alt="cruise event"/>
            </div>
            <div className="col-sm-4 align-items-stretch d-flex">
              <img className="card" src="../images/show/cruiseSquare.jpg" alt="cruise event"/>
            </div>
            <div className="col-sm-4 align-items-stretch d-flex">
              <img className="card" src="../images/show/infoBlock.png" alt="cruise event"/>   
            </div>
          </div> 
          <hr/>
          <h2>Registration</h2>
          <hr/>
          <p>
            If you have attended our event in the past please read event details carefully as we made big changes to registration and show check in.
            <br/>
          </p>
          <ul>
            <li>
              Registration is split up by arrival time. When selecting a ticket on <Link to={{ pathname: "https://www.eventbrite.com/e/2024-salute-to-american-graffiti-car-show-registration-775315969937"}} target="_blank" rel="noopener noreferrer">Eventbrite</Link>, locate your ticket by arrival time, and zone name. See the following map below for reference.
                <div className="row">
                  <div className="col-sm-2"></div>
                  <div className="col-sm-8">
                    <img src="../images/show/parking_zone_time_map.png" alt="parking zones"/>
                  </div>
                  <div className="col-sm-2"></div>
                </div>  
            </li> 
            <li>Each zone has an arrival window that range between 15- 45 minutes.  If you select an arrival time of 6am, you must arrive between 6:00 and 6:45 am to be parked. If you arrive late, or miss your arrival time, you will be parked with the next available group. This may mean you are not parked in the section you reserved. </li> 
            <li>
              There is a set number of parking spaces for each time slot, once the tickets for those spots sell out they are sold out.
            </li>
            <li>
              On show day, vehicles will enter the event through Petaluma Boulevard at the D street entrance, there is no off site staging area as done in events past. Because of the large volume of cars expected for the event, you must arrive during your time window to help us avoid delays and backups into traffic. If you arrive earlier than your designated time slot you will be turned around and told to come back during your arrival window.
            </li>
            <li>
              There are no formal groups. If you wish to park with friends, ensure you purchase tickets for the same time slot, and arrive together at checkin day of. Cruisin' the Boulevard will not guarantee groups will be parked together but will do the best we can to accommodate requests.
            </li>
            <li>
              Due to the large crowd sizes, vehicles can not be moved from their parked possition before the 4 pm cruise time unless you are in the Purple Zone.  If you need to leave with your car before 4pm on May 18th you must make arrangements with us via email prior to show day and you must be parked in the purple zone.  If your vehicle is in any other zone it must remaine parked until the cruise begins at 4pm.  
            </li>
            <li>
              A cruise pass is included with vehicle registration and are for the vehicle that is registered only.
            </li>
            <li>
              Registration for the show is $65 and must be purchased in advance through <Link to={{ pathname: "https://www.eventbrite.com/e/2024-salute-to-american-graffiti-car-show-registration-775315969937"}} target="_blank" rel="noopener noreferrer">Eventbrite</Link> before May 1st.  There are no day of purchases and no refunds.
            </li>
            <li>
              If you experience an issue with a vehicle you registered you may update your registration until May 1st through Eventbrite.  Any replacement vehicle must meet the same requirements or will be subject to rejection. After May 1st printing begins and a replacement vehicle will be approved on a case by case baises.
            </li>
          </ul>

          <h2>Show Rules</h2>
          <hr/>
          <p>
          To ensure that everyone has a good time and is safe while attending the event we ask that the following rules be followed. Those that do not follow these rules will be asked to leave, and risk being expelled from the show completely, which may also include future events.
          </p>
          <ul>
            <li>Evidence of insurance in your vehicle is required</li>
            <li>Proof of DMV registration and valid stickers and license plate is required</li>
            <li>All entry placards must be registered and display registration card visibly on dashboard</li>
            <li>All cruise tags must be displayed in windshield while cruising</li>
            <li>No loud music is allowed to be played from vehicles</li>
            <li>Any display of questionable music, videos, art or other media is not permitted and will be removed from the show</li>
            <li>No open headers</li>
            <li>No burning out or drag racing in or around the event</li>
            <li>Respect others and their rides</li>
            <li>Listen to Crusin' the Boulevard Staff directions and or Requests</li>
            <li>All entries must be driven into the show, on their own power, be able to pull forward, backward, turn and stop on their own power.</li>
            <li>All entries must be an American make vehicle older than 1972 or a documented vehicle from the American Graffiti film.</li>
            <li>All persons operating the vehicle must have a valid driver's license</li>
            <li>No alcohol</li>
          </ul>
            
          <h2>Cruising</h2>
          <hr/>
          <p>
          It is said that the cruising culture was an evolution from the old traditions of strolling down Main Street or around the town square. Although cruising was predominantly boys showing off their cars, individuals would drive slowly down long, straight streets, preferably with many traffic lights to increase opportunities to talk to other cruisers or pedestrians. Cruisers would also gather in parking lots, particularly those of drive-ins and there was always a chance of an impromptu races with other cruisers.
          <br/>
          <br/>
          With each registration, a vehicle will recieve a cruising pass, which can be used to access a dedicated route within the event.  Cruise will start at 4pm on May 18th, with the sound of the Cruisin' the Boulevard's own police car siren.  Cars will line up on the dedicate route single file behind the American Graffiti Cop Car, and cruise just like olden days.  
          <br/> 
          <br/> 
          We also heard your feedback and we expanded the cruise to just over two miles, thats nearly 6 times the size of the route last year.  In addition to historic downtown Petaluma, the cruise route of 2024 includes historic D Street with homes featured in other major films. 
          <br/>
          <br/>
          With this new extension to the cruise route, riding in the back of trucks or outside of a legal seat, will not be permitted while the vehicle is moving.  Please obey all traffic laws and be respectful in these residential areas.
          </p>
          <div className="row">
            <div className="col-sm-2"></div>
            <div className="col-sm-8">
              <img src="../images/show/24_cruise_route.png" alt="cruise map"/>
            </div>
            <div className="col-sm-2"></div>
          </div>
          <p>
            After cruising you may exit the event through the marked exit routes or park in any legal parking space and rejoin the festivies on foot.  The cruising route will remain open no later than 8pm, you may return to crusing as many times as you wish until the route is reopened to regular traffic.
          </p>
        <h2>Vehicles at 6:00 pm</h2>
          <hr/>
          <p>
          At 6 pm the historic streets may begin to reopen by the Petaluma Police Department.  Vehicles remaining downtown after this time, must be legally parked, else they may risk being towed. 
          <br/> 
          If you choose to cruise or not, attendee vehicles must be in a leagl parking space, or moved to one.  Those that are not leagly parked when the streets are reopened risk being towed at the owners expense.
          </p>

      </div>
    </React.Fragment>
  );
}


export default Info;